<template>
  <div>
    <div class="yourstore">
      <p class="yourstore__textblock" v-if="isShopEnabled">
        1. Для покупки товара нажмите «Купить» на карточке товара.<br />
        2. В корзину уже включён пакет обязательных расходов, которые невозможно
        удалить.<br />
        3. Обратите внимание, что стоимость костюмов и визажиста варьируется в
        зависимости от количества образов.<br />
        4. Если вам не хватает денег на все позиции, договоритесь о спонсорстве
        с капитанами других команд и сообщите об этом режиссёру — наставнику. В
        этом случае перед вашим роликом будет показан титр «При поддержке
        определённого департамента»
      </p>
      <p class="yourstore__textblock" v-else>
        Выможете просматривать товары, но купить может только капитан вашей
        команды.
      </p>
      <div class="yourstore__balance" v-if="isShopEnabled">
        <span>Баланс:</span>
        <div class="yourstore__balance-amount">
          {{ formatPrice(userBalance) }}
        </div>
        <div
          class="yourstore__balance-confirm"
          v-if="!isShopSubmitted"
          @click="showConfirmDialog"
        >
          Подтвердить
        </div>
        <div
          class="yourstore__balance-confirmed"
          v-else
          @click="onSaveShopSubmitted(false)"
        >
          Подтверждено
        </div>
      </div>
      <div class="yourstore__items">
        <div
          v-for="product in displayProducts"
          :key="`product-card-sminex-${product._id}`"
          class="yourstore__item"
          :class="{
            active: productPurchasesByProductIDs[product._id] != null,
          }"
        >
          <div class="yourstore__pic">
            <img class="img-responsive" :src="product.image" />
          </div>
          <h3 class="yourstore__item-header">
            {{ product.title }}
          </h3>
          <p class="yourstore__item-desc">
            {{ product.description }}
          </p>
          <div class="yourstore__item-controlswrap">
            <div class="yourstore__item-price">
              <span>{{ formatPrice(product.price) }}</span>
            </div>
            <div class="yourstore__item-controls" v-if="isShopEnabled">
              <div
                v-if="
                  productPurchasesByProductIDs[product._id] == null &&
                  product.price <= userBalance
                "
                class="yourstore__item-control itembuy"
                @click="onProductQuantityUpdate(product, 1)"
              >
                <span>Купить</span>
              </div>
              <template
                v-if="productPurchasesByProductIDs[product._id] != null"
              >
                <div
                  v-if="
                    (product.minPurchaseQuantity != null &&
                      productPurchasesByProductIDs[product._id].quantity >
                        product.minPurchaseQuantity) ||
                    (product.minPurchaseQuantity == null &&
                      productPurchasesByProductIDs[product._id].quantity > 0)
                  "
                  class="yourstore__item-control itemminus"
                  @click="
                    onProductQuantityUpdate(
                      product,
                      productPurchasesByProductIDs[product._id].quantity - 1
                    )
                  "
                >
                  <span>−</span>
                </div>
                <div v-else class="yourstore__item-control itemminus inactive">
                  <span>−</span>
                </div>
                <span class="itemamount">{{
                  productPurchasesByProductIDs[product._id].quantity
                }}</span>
                <div
                  v-if="
                    product.price <= userBalance &&
                    ((product.maxPurchaseQuantity != null &&
                      productPurchasesByProductIDs[product._id].quantity <
                        product.maxPurchaseQuantity) ||
                      (product.maxPurchaseQuantity == null &&
                        productPurchasesByProductIDs[product._id].quantity > 0))
                  "
                  class="yourstore__item-control itemplus"
                  @click="
                    onProductQuantityUpdate(
                      product,
                      productPurchasesByProductIDs[product._id].quantity + 1
                    )
                  "
                >
                  <span>+</span>
                </div>
                <div v-else class="yourstore__item-control itemplus inactive">
                  <span>+</span>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="confirmDialog" :max-width="400" persistent>
      <v-card>
        <v-card-title class="text-subtitle-1" style="word-break: normal">
          Вы действительно подтверждаете свой выбор?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="secondary" text @click="confirmDialog = false">
            Нет
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="
              confirmDialog = false;
              onSaveShopSubmitted(true);
            "
          >
            Да
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import Vue from "vue";

export default {
  name: "SMINEX2023Shop",
  data() {
    return {
      loading: true,
      confirmDialog: false,
      products: [],
      balanceRecords: [],
      productPurchases: [],
      roubleNumberFormat: Intl.NumberFormat("ru-RU"),
      apiMessage: null,
      isImagesModalOpen: false,
      zoomImages: null,
      selectedZoomImageIndex: 0,
    };
  },
  created() {
    this.fetchProducts();
    this.fetchBalanceRecords();
    this.fetchProductPurchases();
  },
  computed: {
    ...mapGetters({
      isShopEnabled: "event/isShopEnabled",
      user: "event/user",
    }),
    productPurchasesByProductIDs() {
      return _.keyBy(this.productPurchases, "product");
    },
    profile() {
      return _.get(this.user, "profile");
    },
    isShopSubmitted() {
      return _.get(this.profile, "custom") === true;
    },
    income() {
      return _.reduce(
        this.balanceRecords,
        (total, { amount }) => {
          return total + amount;
        },
        0
      );
    },
    spent() {
      return _.reduce(
        this.productPurchases,
        (total, { amount }) => {
          return total + amount;
        },
        0
      );
    },
    userBalance() {
      return this.income - this.spent;
    },
    userBalanceStr() {
      return this.roubleNumberFormat.format(this.userBalance);
    },
    displayProducts() {
      return _.map(this.products, (item) => {
        return {
          ...item,
          remaining: Math.max(0, item.quantity - item.purchased),
        };
      });
    },
  },
  methods: {
    ...mapActions({
      getProducts: "event/getShopProducts",
      getBalanceRecords: "event/getShopBalanceRecords",
      getProductPurchases: "event/getShopProductPurchases",
      postProductPurchase: "event/postShopProductPurchase",
      updateMyProfile: "event/updateMyProfile",
    }),
    showConfirmDialog() {
      this.confirmDialog = true;
    },
    async onSaveShopSubmitted(value) {
      const update = { custom: value };
      this.updating = true;
      await this.updateMyProfile(update);
    },
    formatPrice(num) {
      return `${this.roubleNumberFormat.format(num).replace(",", ".")}`;
    },
    async fetchProducts() {
      try {
        this.loading = true;
        const { data } = await this.getProducts();
        this.products = data;
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async fetchBalanceRecords() {
      try {
        const { data } = await this.getBalanceRecords();
        this.balanceRecords = data;
      } catch (err) {
        console.error(err);
      }
    },
    async fetchProductPurchases() {
      try {
        const { data } = await this.getProductPurchases();
        this.productPurchases = data;
      } catch (err) {
        console.error(err);
      }
    },
    async onProductQuantityUpdate(product, newAmount) {
      try {
        this.loading = true;
        const numNewAmount = Number.parseInt(newAmount);
        if (Number.isNaN(numNewAmount)) return;

        const payload = {
          product: product._id,
          quantity: numNewAmount,
        };
        const { data } = await this.postProductPurchase(payload);
        this.apiMessage = data && data.message;
        if (this.apiMessage) {
          Vue.$toast.open({
            type: "info",
            message: this.apiMessage,
            position: "top",
            duration: 5000,
            dismissible: true,
          });
        }

        await this.fetchProducts();
        await this.fetchBalanceRecords();
        await this.fetchProductPurchases();
      } catch (err) {
        this.apiMessage = err && err.message;
        if (this.apiMessage) {
          Vue.$toast.open({
            type: "error",
            message: this.apiMessage,
            position: "top",
            duration: 5000,
            dismissible: true,
          });
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.img-responsive {
  max-width: 100%;
  height: 110px;
}
.yourstore {
  margin: 0;
  padding: 28px 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  /* background-color: #1B252D; */
}
.yourstore__textblock {
  background: #fff;
  color: #000;
  box-sizing: border-box;
  padding: 20px 88px 20px 20px;
  border-radius: 20px;
  margin: 0;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 19.5px;
}
.yourstore__balance {
  display: flex;
  align-items: center;
  justify-content: right;
  flex-wrap: wrap;
  gap: 10px;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
}
.yourstore__balance-amount {
  box-sizing: border-box;
  height: 42px;
  padding: 10px 15px;
  margin: 0;
  background: #ac8b55;
  border-radius: 20px;
}
.yourstore__balance-confirm {
  color: #000;
  box-sizing: border-box;
  height: 42px;
  padding: 10px 15px;
  margin: 0;
  background: #d3d1c5;
  border-radius: 20px;
  text-transform: none;
}
.yourstore__balance-confirm:hover {
  cursor: pointer;
  background: #bfbdb1;
}
.yourstore__balance-confirmed {
  color: #000;
  box-sizing: border-box;
  height: 42px;
  padding: 10px 15px;
  margin: 0;
  background: rgba(211, 209, 197, 0.4);
  border-radius: 20px;
  text-transform: none;
}
.yourstore__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 0;
  padding: 0;
}
.yourstore__item {
  background: #fff;
  color: #000;
  box-sizing: border-box;
  /* height: 275px; */
  padding: 10px;
  margin: 0;
  width: 270px;
  border-radius: 10px;
  display: grid;
  grid-template-rows: 140px 22px 40px 22px;
  gap: 10px;
}
.yourstore__item.active {
  background: #f5e4c9;
}
.yourstore__pic {
  background-color: #1b252d;
  background-image: url("https://cdn.bahinsky.ru/sminex-2023/shop/storeitem-bck.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 140px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
}
.yourstore__pic img {
  margin: 0;
  padding: 0;
}
.yourstore__item-header {
  font-weight: 700;
  font-size: 18px;
  line-height: 21.94px;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  white-space: nowrap;
  margin: 0;
  padding: 0;
}
.yourstore__item-desc {
  font-weight: 400;
  font-size: 10px;
  line-height: 12.19px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  margin-top: -6px;
  padding: 0;
}
.yourstore__item-controlswrap {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
}
.yourstore__item-price {
  padding: 5px 10px;
  margin: 0;
  border-radius: 10px;
  background: #000;
  color: #fff;
  font-weight: 500;
  font-size: 10px;
  line-height: 12.19px;
}
.yourstore__item-price span {
  margin: 0;
  padding: 0;
}
.yourstore__item-controls {
  box-sizing: border-box;
  width: 102px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.yourstore__item-control {
  box-sizing: border-box;
  padding: 5px 10px;
  margin: 0;
  border-radius: 10px;
  background: #ac8b55;
  color: #fff;
  font-weight: 500;
  font-size: 10px;
  line-height: 12.19px;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.2s ease-in;
}
.yourstore__item-control:hover {
  cursor: pointer;
  background: #8e6d37;
  transition: all 0.2s ease-in;
}
.itembuy {
  width: 100%;
  margin: 0;
}
.itemminus,
.itemplus {
  width: 34px;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  padding: 0 10px;
}
.itemminus.inactive,
.itemplus.inactive {
  color: rgba(255, 255, 255, 0.2);
}
.itemminus.inactive:hover,
.itemplus.inactive:hover {
  cursor: default;
  background: #ac8b55;
}
.itemamount {
  margin: 0;
  padding: 0;
  font-size: 10px;
  font-weight: 500;
  line-height: 12.19px;
  color: #000;
}
</style>
