<template>
  <div class="main">
    <div class="main-header">
      <h2>Заработанные баллы</h2>
    </div>
    <div class="main-results">
      <div
        v-for="(rowItems, index) in items"
        class="main-results__row"
        :class="{
          clearfix: index == 0,
        }"
        :key="`budget-row-${index}`"
      >
        <div
          v-for="(item, itemIndex) in rowItems"
          :key="`budget-item-${index}-${itemIndex}`"
          class="main-results__item"
        >
          <div class="main-results__team">
            <span>{{ item[0] }}</span>
          </div>
          <div class="main-results__points">
            <span>{{ item[1] }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      meta: "event/eventMeta",
    }),
    items() {
      const rawItems = _.map(_.get(this.meta, "budget"), (item) => item);
      return [_.slice(rawItems, 0, 7), _.slice(rawItems, 7)];
    },
  },
};
</script>

<style scoped>
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
.main {
  background: #0e1821;
  /* background: url(main_backgroud.png) repeat; */
  background-size: 50%;
  padding: 46px 50px;
  border-radius: 20px;
  /* margin: 20px 50px; */
  margin: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.main-header {
  text-transform: uppercase;
  margin-bottom: 50px;
}
.main-header h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 48.76px;
  color: #baa47e;
}
.main-results {
  padding: 180px 180px 150px;
  margin: -80px -150px;
  background: url(/sminex-2023/img/main-results-bck-1.png) no-repeat top right,
    url(/sminex-2023/img/main-results-bck-2.png) no-repeat top 53% left 70px;
}
.main-results__row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;
  border-radius: 10px;
  border-collapse: separate;
  overflow: hidden;
  perspective: 1px;
}
.main-results__row:first-child {
  margin-top: 0;
  background: url(/sminex-2023/img/main-results-bck-2.png) no-repeat;
}
.main-results__item {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 17.07px;
  width: 110px;
  border-left: 1px solid #5c4021;
  background-color: #fff;
}
.main-results__item:first-child {
  border-left: none;
}
.main-results__item:last-child {
  border-radius: 0 0 10px 0;
  border-collapse: separate;
  overflow: hidden;
  perspective: 1px;
}
.main-results__team {
  padding: 5px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #ac8b55;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
}
.main-results__team span {
  max-height: 50px;
  max-width: 100px;
  word-wrap: break-word;
}
.main-results__points {
  padding: 5px 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  background-color: #fff;
}
.main-roadmap {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr repeat(10, 2fr);
  grid-template-rows: repeat(16, 50px);
  grid-template-areas:
    "stage stage stage stage stage stage stage stage stage stage stage"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "footer footer footer footer footer footer footer footer footer footer footer";
}
.main-roadmap__header {
  grid-area: stage;
  display: grid;
  grid-template-columns: 3fr repeat(10, 2fr);
}
.main-roadmap__stage {
  box-sizing: border-box;
  border-bottom: 1px solid #dbb286;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.main-roadmap__teams {
  grid-area: team;
  box-sizing: border-box;
  border-right: 1px solid #dbb286;
  display: flex;
  flex-direction: column;
}
.main-roadmap__team {
  box-sizing: border-box;
  padding: 5px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: right;
  text-align: right;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 12px;
  overflow: hidden;
  word-break: break-all;
  hyphens: auto;
}
.main-roadmap__team span {
  max-width: 100%;
}
.main-roadmap__footer {
  grid-area: footer;
  display: grid;
  grid-template-columns: 3fr repeat(10, 2fr);
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #fff;
}
.main-roadmap__stagename {
  border-top: 1px solid #dbb286;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  word-break: break-all;
  hyphens: auto;
}
.main-roadmap__progresstracks {
  z-index: 1;
  grid-area: progress;
  display: grid;
  grid-template-columns: 1fr repeat(9, 2fr) 1fr;
}
.main-roadmap__progresstrack {
  height: 100%;
  box-sizing: border-box;
  border-right: 1px dashed rgba(219, 178, 134, 0.6);
}
.main-roadmap__progressbars {
  z-index: 2;
  grid-area: progress;
  display: grid;
  grid-template-columns: 1fr repeat(9, 2fr) 1fr;
  grid-template-rows: repeat(14, 1fr);
}
.main-roadmap__progressbar {
  grid-column-start: 1;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    linear-gradient(270deg, #b8913e -1.6%, #e0ca8d 54.6%, #826616 100%);
  background-size: 100% 50%;
  background-repeat: no-repeat;
  background-position: 25%;
}
</style>