<template>
  <div class="main">
    <div class="main-header">
      <h2>Road Map</h2>
    </div>
    <div class="main-roadmap">
      <div class="main-roadmap__header">
        <div></div>
        <div class="main-roadmap__stage">
          <img src="/sminex-2023/img/stage-1.png" />
        </div>
        <div class="main-roadmap__stage">
          <img src="/sminex-2023/img/stage-2.png" />
        </div>
        <div class="main-roadmap__stage">
          <img src="/sminex-2023/img/stage-3.png" />
        </div>
        <div class="main-roadmap__stage">
          <img src="/sminex-2023/img/stage-4.png" />
        </div>
        <div class="main-roadmap__stage">
          <img src="/sminex-2023/img/stage-5.png" />
        </div>
        <div class="main-roadmap__stage">
          <img src="/sminex-2023/img/stage-6.png" />
        </div>
        <div class="main-roadmap__stage">
          <img src="/sminex-2023/img/stage-7.png" />
        </div>
        <div class="main-roadmap__stage">
          <img src="/sminex-2023/img/stage-8.png" />
        </div>
        <div class="main-roadmap__stage">
          <img src="/sminex-2023/img/stage-9.png" />
        </div>
        <div class="main-roadmap__stage">
          <img src="/sminex-2023/img/stage-10.png" />
        </div>
      </div>
      <div class="main-roadmap__teams">
        <div
          v-for="(item, index) in items"
          :key="`roadmap-team-${index}`"
          class="main-roadmap__team"
        >
          <span>{{ item[0] }}</span>
        </div>
      </div>
      <div class="main-roadmap__progresstracks">
        <div class="main-roadmap__progresstrack"></div>
        <div class="main-roadmap__progresstrack"></div>
        <div class="main-roadmap__progresstrack"></div>
        <div class="main-roadmap__progresstrack"></div>
        <div class="main-roadmap__progresstrack"></div>
        <div class="main-roadmap__progresstrack"></div>
        <div class="main-roadmap__progresstrack"></div>
        <div class="main-roadmap__progresstrack"></div>
        <div class="main-roadmap__progresstrack"></div>
        <div class="main-roadmap__progresstrack"></div>
      </div>
      <div class="main-roadmap__progressbars">
        <div
          v-for="(item, index) in items"
          :key="`roadmap-progress-${index}`"
          class="main-roadmap__progressbar"
          :style="{
            'grid-column-end': item[1] + 1,
            visibility: item[1] === 0 ? 'hidden' : '',
          }"
        ></div>
      </div>
      <div class="main-roadmap__footer">
        <div></div>
        <div class="main-roadmap__stagename"><span>Идея</span></div>
        <div class="main-roadmap__stagename"><span>Сценарий</span></div>
        <div class="main-roadmap__stagename"><span>ТЗ на съёмку</span></div>
        <div class="main-roadmap__stagename"><span>Раскадровка</span></div>
        <div class="main-roadmap__stagename"><span>Финансирование</span></div>
        <div class="main-roadmap__stagename"><span>Съёмка</span></div>
        <div class="main-roadmap__stagename"><span>Монтаж</span></div>
        <div class="main-roadmap__stagename"><span>Саунд-дизайн</span></div>
        <div class="main-roadmap__stagename"><span>Цветокоррекция</span></div>
        <div class="main-roadmap__stagename"><span>Готово</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      meta: "event/eventMeta",
    }),
    items() {
      const rawItems = _.map(_.get(this.meta, "roadmap"), (item) => item);
      return rawItems;
    },
  },
};
</script>

<style scoped>
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
.main {
  object-fit: unset !important;
  background: #0e1821;
  /* background: url(main_backgroud.png) repeat; */
  background-size: 50%;
  padding: 46px 30px;
  border-radius: 20px;
  /* margin: 20px 50px; */
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.main-header {
  text-transform: uppercase;
  margin-bottom: 50px;
}
.main-header h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 48.76px;
  color: #baa47e;
}
.main-results {
  padding: 180px 180px 150px;
  margin: -80px -150px;
  background: url(/sminex-2023/img/main-results-bck-1.png) no-repeat top right,
    url(/sminex-2023/img/main-results-bck-2.png) no-repeat top 53% left 70px;
}
.main-results__row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;
  border-radius: 10px;
  border-collapse: separate;
  overflow: hidden;
  perspective: 1px;
}
.main-results__row:first-child {
  margin-top: 0;
  background: url(/sminex-2023/img/main-results-bck-2.png) no-repeat;
}
.main-results__item {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 17.07px;
  width: 110px;
  border-left: 1px solid #5c4021;
  background-color: #fff;
}
.main-results__item:first-child {
  border-left: none;
}
.main-results__item:last-child {
  border-radius: 0 0 10px 0;
  border-collapse: separate;
  overflow: hidden;
  perspective: 1px;
}
.main-results__team {
  padding: 5px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #ac8b55;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
}
.main-results__team span {
  max-height: 50px;
  max-width: 100px;
  word-wrap: break-word;
}
.main-results__points {
  padding: 5px 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  background-color: #fff;
}
.main-roadmap {
  width: 100%;
  display: grid;
  grid-template-columns: 85px repeat(10, 2fr);
  grid-template-rows: repeat(16, 50px);
  grid-template-areas:
    "stage stage stage stage stage stage stage stage stage stage stage"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "team progress progress progress progress progress progress progress progress progress progress"
    "footer footer footer footer footer footer footer footer footer footer footer";
}
.main-roadmap__header {
  grid-area: stage;
  display: grid;
  grid-template-columns: 85px repeat(10, 2fr);
}
.main-roadmap__stage {
  box-sizing: border-box;
  border-bottom: 1px solid #dbb286;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.main-roadmap__stage img {
  object-fit: unset;
  width: unset;
}
.main-roadmap__teams {
  grid-area: team;
  box-sizing: border-box;
  padding: 15px 0;
  border-right: 1px solid #dbb286;
  display: flex;
  flex-direction: column;
}
.main-roadmap__team {
  box-sizing: border-box;
  padding: 5px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: right;
  text-align: right;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 12px;
  overflow: hidden;
  word-break: normal;
  hyphens: auto;
}
.main-roadmap__team span {
  max-width: 100%;
}
.main-roadmap__footer {
  grid-area: footer;
  display: grid;
  grid-template-columns: 85px repeat(10, 2fr);
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #fff;
}
.main-roadmap__stagename {
  border-top: 1px solid #dbb286;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  word-break: normal;
  hyphens: auto;
}
.main-roadmap__progresstracks {
  z-index: 1;
  padding: 15px 0;
  margin: -15px 0;
  grid-area: progress;
  display: grid;
  grid-template-columns: 1fr repeat(9, 2fr) 1fr;
}
.main-roadmap__progresstrack {
  height: 100%;
  background: url(/sminex-2023/img/progresstracks.svg) repeat-y top right;
  background-size: 1px;
}
.main-roadmap__progressbars {
  z-index: 2;
  padding: 15px 0;
  grid-area: progress;
  display: grid;
  grid-template-columns: 1fr repeat(9, 2fr) 1fr;
  grid-template-rows: repeat(14, 1fr);
}
.main-roadmap__progressbar {
  grid-column-start: 1;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    linear-gradient(270deg, #b8913e -1.6%, #e0ca8d 54.6%, #826616 100%);
  background-size: 100% 50%;
  background-repeat: no-repeat;
  background-position: 25%;
}
</style>