<template>
  <div>
    <app-event :event-id="eventId" auth="login-only" :mapping="mapping">
      <template v-slot:title>
        <h2
          style="
            color: #000;
            font-variant-numeric: lining-nums proportional-nums;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          "
        >
          {{ title }}
        </h2>
      </template>
      <template v-slot:left-menu>
        <v-navigation-drawer
          app
          :value="drawer || !$vuetify.breakpoint.mobile"
          @input="setDrawer"
          dark
          class="primary"
          :style="{
            backgroundColor: `#0E1821 !important`,
            backdropFilter: 'blur(10px)',
          }"
        >
          <div class="d-flex flex-column justify-center align-center mt-4 mb-4">
            <v-img
              :src="require('./assets/logo.png')"
              width="136"
              height="32"
              class="ma-4"
            />
          </div>
          <v-list nav rounded color="transparent">
            <v-list-item-group :value="selectedItem">
              <v-list-item
                v-for="(item, index) in visibleItems"
                :key="index"
                @click="onItemSelect(item.id)"
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.title"
                    class="d-flex justify-center align-center"
                    style="text-shadow: 1px 1px 2px black"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </template>
      <template v-slot:logo>
        <div
          style="
            padding: 1px 4px 4px 0px;
            border-radius: 8px;
            background-color: #355d59;
            margin: 16px;
          "
        >
          <v-img :src="require('./assets/logo.png')" class="ma-4" />
        </div>
      </template>
    </app-event>
  </div>
</template>

<script>
import AppEvent from "@/components/event/Event.vue";
import eventMenuMixin from "../../mixins/eventMenuMixin";
import themeMixin from "@/mixins/eventTheme";
import Quest from "./Quest";
import Roadmap from "./Roadmap.vue";
import Budget from "./Budget.vue";
import Shop from "./Shop/Index.vue";
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import _ from "lodash";

export default {
  name: "AppSminex2023",
  components: {
    AppEvent,
  },
  mixins: [eventMenuMixin, themeMixin],
  data() {
    return {
      name: "",
      updating: false,
      mapping: {
        quest: Quest,
        roadmap: Roadmap,
        budget: Budget,
        shop: Shop,
      },
    };
  },
  created() {
    console.log({ eventId: this.eventId });
    this.name = this.userName;
  },
  watch: {
    userName() {
      this.name = this.userName;
    },
  },
  computed: {
    ...mapGetters({
      user: "event/user",
    }),
    ...mapState(["drawer"]),
    profile() {
      return _.get(this.user, "profile");
    },
    userName() {
      return _.get(this.profile, "name");
    },
    eventId() {
      return this.$route.meta.eventId || "sminex-2023";
    },
  },
  methods: {
    ...mapActions({
      updateMyProfile: "event/updateMyProfile",
    }),
    ...mapMutations({
      setDrawer: "drawer",
    }),
    async onSaveUserName() {
      const update = { name: this.name };
      this.updating = true;
      await this.updateMyProfile(update);
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Montserrat");

$font-family: "Montserrat";

.v-application {
  [class*="text-"] {
    font-family: $font-family, sans-serif !important;
  }
  font-family: $font-family, sans-serif !important;
}
</style>
